import _ from 'lodash'
import { useEffect, useMemo, useState } from 'react'
import {
  DataGridPro,
  DataGridProProps,
  GridLoadingOverlayProps,
  GridToolbar,
} from '@mui/x-data-grid-pro'
import { useWindowSize } from 'react-use'

const slots = {
  toolbar: GridToolbar,
}

export const loadingOverlay = {
  variant: 'skeleton',
  noRowsVariant: 'linear-progress',
} as GridLoadingOverlayProps

export const loadingMoreOverlay = {
  variant: 'linear-progress',
  noRowsVariant: 'linear-progress',
} as GridLoadingOverlayProps

const OFFSET_HEIGHT = 200

// ! Improve the performance of the DataGrid using the recommendations from https://mui.com/x/react-data-grid/performance/
const DataGrid = ({
  slotProps: propsSlotProps,
  ...props
}: DataGridProProps) => {
  const [height, setHeight] = useState(window.innerHeight - OFFSET_HEIGHT)

  const { height: windowHeight } = useWindowSize()
  useEffect(() => {
    setHeight(windowHeight - OFFSET_HEIGHT)
  }, [windowHeight])

  const slotProps = useMemo(() => {
    return _.merge(
      {
        loadingOverlay,
        toolbar: {
          csvOptions: { allColumns: true },
        },
      },
      propsSlotProps
    )
  }, [propsSlotProps])

  return (
    <div
      style={{
        height,
        width: '100%',
      }}
    >
      <DataGridPro
        disableRowSelectionOnClick
        slots={slots}
        slotProps={slotProps}
        autoPageSize
        {...props}
      />
    </div>
  )
}

export default DataGrid
